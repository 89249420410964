// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import BarcodesScanner from "../../blocks/BarcodesScanner/src/BarcodesScanner";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import GoogleCalendarSync from "../../blocks/GoogleCalendarSync/src/GoogleCalendarSync";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import CustomForm2 from "../../blocks/CustomForm2/src/CustomForm2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import VideoEmbeddingYoutube from "../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube";
import CameraAccess from "../../blocks/CameraAccess/src/CameraAccess";
import ApiIntegration8 from "../../blocks/ApiIntegration8/src/ApiIntegration8";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Search from "../../blocks/Search/src/Search";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import SendAMessage from "../../blocks/SendAMessage/src/SendAMessage";
import LandingPage2 from "../../blocks/LandingPage2/src/LandingPage2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Download from "../../blocks/Download/src/Download";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";



const routeMap = {
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},
BarcodesScanner:{
 component:BarcodesScanner,
path:"/BarcodesScanner"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
GoogleCalendarSync:{
 component:GoogleCalendarSync,
path:"/GoogleCalendarSync"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
CustomForm2:{
 component:CustomForm2,
path:"/CustomForm2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
VideoEmbeddingYoutube:{
 component:VideoEmbeddingYoutube,
path:"/VideoEmbeddingYoutube"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
ApiIntegration8:{
 component:ApiIntegration8,
path:"/ApiIntegration8"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Search:{
 component:Search,
path:"/Search"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
SendAMessage:{
 component:SendAMessage,
path:"/SendAMessage"},
LandingPage2:{
 component:LandingPage2,
path:"/LandingPage2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Download:{
 component:Download,
path:"/Download"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;