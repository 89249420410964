"use strict";


const baseURL = "https://adihexapp-73087-ruby.b73087.dev.eastus.az.svc.builder.cafe";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
